<template>
    <div class="info">
      <van-popup position="bottom" v-model="visible">
        <van-cell v-for="(item, index) in detailData.files" :key="index">
          <van-button type="info" round class="files-button" @click="download(item.url, item.name)">
            <span class="fileName">{{item.name}}</span>
          </van-button>
        </van-cell>
      </van-popup>
      <div class="infoBox">
        <div class="title">
          <p>【{{detailData.noticeTypeStr}}】{{detailData.title}}</p>
          <span>发布时间：{{detailData.createTime}}</span>
        </div>
        <van-divider :hairline="false" />
        <div class="content" v-html="detailData.content"></div>
        <van-row class="btns" v-if="!visible && detailData.files && detailData.files.length > 0">
          <van-col :span="24">
            <van-button type="info" size="large" round @click="showUrl">点此下载或复制文件</van-button>
          </van-col>
        </van-row>
      </div>
    </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'
import {dowloadByUrl} from "@/utils/utils"
export default {
  data () {
    return {
      detailData: {},
      id: 0,
      visible: false
    }
  },
  components :{
    topBar
  },
  methods: {
    getDetail () {
      this.$http({
        url: this.$http.adornUrl('/wxapp/commonApp/notice/info/')+ this.id,
        method: 'post',
      }).then(({data})=> {
          if (data.code == 0) {
            this.detailData = data.commonAppNotice
            this.$toast.clear()
          } else {
            console.log(data);
          this.$toast.clear()
          this.$toast.fail(data.msg);
          }
        })
    },
    showUrl () {
      this.visible = true
    },
    download (url, name) {
      let _url = url
      if (url.indexOf("hzuht.com") < 0 && (!this.$globalData.domain && _url.indexOf(this.$globalData.userInfo.domain) < 0)) {
        let cInput = document.createElement('input')
        cInput.value = url
        document.body.appendChild(cInput)
        cInput.select()
        document.execCommand('Copy')
        this.$toast.success('网址复制成功')
        cInput.remove()
        this.visible = false
      } else {
        let division = url.split('?')[0].split('.')[4]
        dowloadByUrl(url, name + '.' + division)
      }
    }
  },
  created () {
    this.$toast.loading({
      duration: 0,
      message: '加载中...',
      forbidClick: true,
    })
    this.id = this.$route.query.id
    this.getDetail()
  }
}
</script>
<style>
  a {
  color: rgb(4, 50, 255);
}
</style>
<style lang="scss" scoped>
  .van-divider{
    border-color: #EEEEEE;
  }
  .info {
    min-height: 100vh;
    margin-top: 30px;
    padding: 0 20px;
    background-color: #f5f5f5;
    .files-button .fileName {
      width: 100%;
      height: 60px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis
    }
    ::v-deep .van-button--round {
      width: 100%;
    }
    .infoBox {
      background-color: #fff;
      padding: 30px;
      .title {
        p {
          font-size: 36px;
          font-weight: 600;
          margin: 10px 0 20px;
        }
        .user {
          font-size: 13px;
          font-weight: 400;
          color: #676767;
          margin: 10px 0 20px;
        }
        span {
          font-size: 30px;
          color: #676767;
        }
      }
      .content {
        font-size: 30px;
        color: #3B3B3B;
      }
    }
  }
</style>
